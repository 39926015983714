import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { backendUrl } from "../../../rootUrl";
const activateUrl = backendUrl + "/api/auth/activate/";
const resendactivateUrl = backendUrl + "/api/auth/resend-activate-code/";
const sendCodeUrl = backendUrl + "/api/auth/send-activate-code/";
const resetPasswordUrl = backendUrl + "/api/auth/reset-user-password/";

export const activateUser = createAsyncThunk(
  "auth/activate",
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(activateUrl, { ...data }, config);
      return res.data;
    } catch (err) {
      // if(!err.response){
      //     throw err
      // }
      return rejectWithValue(err.response.data);
    }
  }
);

export const resendActivateCode = createAsyncThunk(
  "auth/resend-activate-code",
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(resendactivateUrl, { ...data }, config);
      return res.data;
    } catch (err) {
      // if(!err.response){
      //     throw err
      // }
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendCode = createAsyncThunk(
  "auth/send-code",
  async ({ username, cb }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(sendCodeUrl, { username }, config);
      if (cb && res.status === 200 && res?.data?.success) cb();
      return res.data;
    } catch (err) {
      // if(!err.response){
      //     throw err
      // }
      return rejectWithValue(err.response.data);
    }
  }
);

export const resetUserPassword = createAsyncThunk(
  "auth/reset-user-password",
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(resetPasswordUrl, { ...data }, config);
      return res.data;
    } catch (err) {
      // if(!err.response){
      //     throw err
      // }
      return rejectWithValue(err.response.data);
    }
  }
);
